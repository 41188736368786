import React from 'react';
import { Button } from 'react-bootstrap';

import styles from './Home.module.scss';

const HomePage: React.FunctionComponent = () => {
    return (
        <div className='section'>
            <div className={styles.homePage}>
                <div className={styles.logo}>
                    <div className={styles.welcomeText}>
                        <span className={styles.welcomeText_primary}>
                            Dawid & Justyna
                        </span>
                        <span className={styles.welcomeText_date}>
                            09.13.2025
                        </span>
                        <span className={styles.welcomeText_secondary}>
                            Chicago, IL
                        </span>
                    </div>
                    <div className={styles.buttons}>
                        <Button
                            variant='outline-light'
                            onClick={() => window.fullpageApi.moveTo('rsvp')}
                        >
                            RSVP
                        </Button>
                    </div>
                </div>
                <div className={styles.image}></div>
            </div>
        </div>
    );
};
export default HomePage;
