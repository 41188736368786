import ReactFullpage, { Item } from '@fullpage/react-fullpage';
import React from 'react';

import JuniperNavbar from './components/JuniperNavbar';
import Section from './components/Section';
import licenses from './config/fullpage.json';
import HomePage from './pages/Home';
import RSVP from './pages/RSVP';

function App() {
    const [activeSection, setActiveSection] = React.useState<string | number>(
        'home'
    );

    return (
        <>
            <JuniperNavbar
                activeSection={activeSection}
                setActiveSection={setActiveSection}
            />
            <ReactFullpage
                {...licenses.fullPage}
                afterLoad={(_: Item, destination: Item) => {
                    setActiveSection(destination.anchor);
                }}
                render={({ fullpageApi }) => {
                    window.fullpageApi = fullpageApi;

                    return (
                        <ReactFullpage.Wrapper>
                            <HomePage></HomePage>
                            <Section
                                id='details'
                                header='Wedding Details'
                            >
                                Lorem ipsum odor amet, consectetuer adipiscing elit. Eros hac mauris nec nostra arcu
                                nulla. Ultrices ad habitasse eros pharetra nostra dis. Tincidunt ipsum arcu vulputate
                                duis dapibus primis auctor non. Montes vestibulum malesuada luctus nisl montes id aenean
                                odio. Vestibulum elementum ultrices augue litora pellentesque phasellus magna? Ligula
                                magna primis, finibus ultrices sit purus dictumst. Elementum facilisis posuere semper
                                facilisi tellus mollis rhoncus torquent.

                                Eget lorem accumsan turpis molestie inceptos. Nisl malesuada fermentum tincidunt duis
                                nec. Libero enim nec mi molestie eget; aptent sem nulla. Turpis non platea lorem
                                interdum eu ullamcorper. Ipsum adipiscing felis convallis venenatis ante risus massa.
                                Est iaculis laoreet habitant duis elementum. Lobortis quisque est dictumst; aenean
                                varius nisl potenti. Quisque vehicula dolor facilisis nunc consequat integer tellus in
                                conubia. Varius tincidunt semper inceptos aenean ac.

                                Rhoncus eu senectus tempor tempor platea cursus luctus. Potenti eleifend ad odio magnis
                                per dui nascetur ut. Natoque proin semper at tempor hac in nam taciti. Auctor laoreet
                                purus libero penatibus fusce; mus varius tristique. Aptent aptent nascetur per; tempor
                                congue vehicula non. Ligula euismod phasellus purus condimentum facilisis facilisi.

                                Molestie nullam penatibus scelerisque maecenas posuere. Nostra eget vitae nisi non
                                finibus. Curabitur a conubia leo aliquet litora ullamcorper. Commodo viverra nibh ligula
                                vivamus rhoncus metus pellentesque augue netus. Praesent purus efficitur litora sociosqu
                                potenti laoreet. Per pulvinar pellentesque congue rutrum viverra laoreet cursus. Amet
                                fermentum morbi leo maximus, luctus congue ultrices. Vehicula auctor molestie ut
                                vulputate ad iaculis massa etiam posuere.

                                Lobortis suspendisse orci, imperdiet felis habitant tellus. Nam parturient laoreet sed
                                curae ultricies accumsan. Class adipiscing eros pellentesque netus at. Non arcu taciti
                                gravida class litora sem sollicitudin lorem tempus. Vel condimentum condimentum; commodo
                                ad enim turpis mollis. Semper varius convallis duis purus; tellus cras habitasse
                                commodo. Cubilia iaculis parturient facilisis felis aliquet montes nisl est. Nunc eros
                                luctus molestie vitae; aliquam massa. Torquent natoque dis nec viverra donec lacinia
                                felis.
                            </Section>
                            <Section
                                id='accommodations'
                                header='Accommodations'
                            >
                                Lorem ipsum odor amet, consectetuer adipiscing elit. Eros hac mauris nec nostra arcu
                                nulla. Ultrices ad habitasse eros pharetra nostra dis. Tincidunt ipsum arcu vulputate
                                duis dapibus primis auctor non. Montes vestibulum malesuada luctus nisl montes id aenean
                                odio. Vestibulum elementum ultrices augue litora pellentesque phasellus magna? Ligula
                                magna primis, finibus ultrices sit purus dictumst. Elementum facilisis posuere semper
                                facilisi tellus mollis rhoncus torquent.

                                Eget lorem accumsan turpis molestie inceptos. Nisl malesuada fermentum tincidunt duis
                                nec. Libero enim nec mi molestie eget; aptent sem nulla. Turpis non platea lorem
                                interdum eu ullamcorper. Ipsum adipiscing felis convallis venenatis ante risus massa.
                                Est iaculis laoreet habitant duis elementum. Lobortis quisque est dictumst; aenean
                                varius nisl potenti. Quisque vehicula dolor facilisis nunc consequat integer tellus in
                                conubia. Varius tincidunt semper inceptos aenean ac.

                                Rhoncus eu senectus tempor tempor platea cursus luctus. Potenti eleifend ad odio magnis
                                per dui nascetur ut. Natoque proin semper at tempor hac in nam taciti. Auctor laoreet
                                purus libero penatibus fusce; mus varius tristique. Aptent aptent nascetur per; tempor
                                congue vehicula non. Ligula euismod phasellus purus condimentum facilisis facilisi.

                                Molestie nullam penatibus scelerisque maecenas posuere. Nostra eget vitae nisi non
                                finibus. Curabitur a conubia leo aliquet litora ullamcorper. Commodo viverra nibh ligula
                                vivamus rhoncus metus pellentesque augue netus. Praesent purus efficitur litora sociosqu
                                potenti laoreet. Per pulvinar pellentesque congue rutrum viverra laoreet cursus. Amet
                                fermentum morbi leo maximus, luctus congue ultrices. Vehicula auctor molestie ut
                                vulputate ad iaculis massa etiam posuere.

                                Lobortis suspendisse orci, imperdiet felis habitant tellus. Nam parturient laoreet sed
                                curae ultricies accumsan. Class adipiscing eros pellentesque netus at. Non arcu taciti
                                gravida class litora sem sollicitudin lorem tempus. Vel condimentum condimentum; commodo
                                ad enim turpis mollis. Semper varius convallis duis purus; tellus cras habitasse
                                commodo. Cubilia iaculis parturient facilisis felis aliquet montes nisl est. Nunc eros
                                luctus molestie vitae; aliquam massa. Torquent natoque dis nec viverra donec lacinia
                                felis.
                            </Section>
                            <Section
                                id='qa'
                                header='Q&A'
                            >
                                Lorem ipsum odor amet, consectetuer adipiscing elit. Eros hac mauris nec nostra arcu
                                nulla. Ultrices ad habitasse eros pharetra nostra dis. Tincidunt ipsum arcu vulputate
                                duis dapibus primis auctor non. Montes vestibulum malesuada luctus nisl montes id aenean
                                odio. Vestibulum elementum ultrices augue litora pellentesque phasellus magna? Ligula
                                magna primis, finibus ultrices sit purus dictumst. Elementum facilisis posuere semper
                                facilisi tellus mollis rhoncus torquent.

                                Eget lorem accumsan turpis molestie inceptos. Nisl malesuada fermentum tincidunt duis
                                nec. Libero enim nec mi molestie eget; aptent sem nulla. Turpis non platea lorem
                                interdum eu ullamcorper. Ipsum adipiscing felis convallis venenatis ante risus massa.
                                Est iaculis laoreet habitant duis elementum. Lobortis quisque est dictumst; aenean
                                varius nisl potenti. Quisque vehicula dolor facilisis nunc consequat integer tellus in
                                conubia. Varius tincidunt semper inceptos aenean ac.

                                Rhoncus eu senectus tempor tempor platea cursus luctus. Potenti eleifend ad odio magnis
                                per dui nascetur ut. Natoque proin semper at tempor hac in nam taciti. Auctor laoreet
                                purus libero penatibus fusce; mus varius tristique. Aptent aptent nascetur per; tempor
                                congue vehicula non. Ligula euismod phasellus purus condimentum facilisis facilisi.

                                Molestie nullam penatibus scelerisque maecenas posuere. Nostra eget vitae nisi non
                                finibus. Curabitur a conubia leo aliquet litora ullamcorper. Commodo viverra nibh ligula
                                vivamus rhoncus metus pellentesque augue netus. Praesent purus efficitur litora sociosqu
                                potenti laoreet. Per pulvinar pellentesque congue rutrum viverra laoreet cursus. Amet
                                fermentum morbi leo maximus, luctus congue ultrices. Vehicula auctor molestie ut
                                vulputate ad iaculis massa etiam posuere.

                                Lobortis suspendisse orci, imperdiet felis habitant tellus. Nam parturient laoreet sed
                                curae ultricies accumsan. Class adipiscing eros pellentesque netus at. Non arcu taciti
                                gravida class litora sem sollicitudin lorem tempus. Vel condimentum condimentum; commodo
                                ad enim turpis mollis. Semper varius convallis duis purus; tellus cras habitasse
                                commodo. Cubilia iaculis parturient facilisis felis aliquet montes nisl est. Nunc eros
                                luctus molestie vitae; aliquam massa. Torquent natoque dis nec viverra donec lacinia
                                felis.
                            </Section>
                            <RSVP></RSVP>
                        </ReactFullpage.Wrapper>
                    );
                }}
            />
        </>
    );
}

export default App;
