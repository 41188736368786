// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Yy5BMqwlpf92VPYyOyKa{margin:2rem auto;padding:1rem 4rem;width:100vw;height:90vh;max-width:1028px;justify-content:center;width:100vw}h1{display:block;margin-bottom:2rem}.eDxobW62eot4i9gFP8JT{flex-grow:1}`, "",{"version":3,"sources":["webpack://./src/components/Section/Section.module.scss"],"names":[],"mappings":"AAAA,sBACI,gBAAA,CACA,iBAAA,CACA,WAAA,CACA,WAAA,CACA,gBAAA,CACA,sBAAA,CACA,WAAA,CAGJ,GACI,aAAA,CACA,kBAAA,CAGJ,sBACI,WAAA","sourcesContent":[".sectionContainer {\n    margin: 2rem auto;\n    padding: 1rem 4rem;\n    width: 100vw;\n    height: 90vh;\n    max-width: 1028px;\n    justify-content: center;\n    width: 100vw;\n}\n\nh1 {\n    display: block;\n    margin-bottom: 2rem;\n}\n\n.contents {\n    flex-grow: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sectionContainer": `Yy5BMqwlpf92VPYyOyKa`,
	"contents": `eDxobW62eot4i9gFP8JT`
};
export default ___CSS_LOADER_EXPORT___;
