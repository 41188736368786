import React from 'react';

import styles from './Section.module.scss';

interface SectionProps {
    id: string;
    header: string;
    children: React.ReactNode;
}

const Section: React.FunctionComponent<SectionProps> = (props) => {
    return (
        <div className='section'>
            <div className={styles.sectionContainer}>
                <h1>{props.header}</h1>
                <div className={styles.contents}>{props.children}</div>
            </div>
        </div>
    );
};
export default Section;
